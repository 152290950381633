// @import url('https://fonts.googleapis.com/css?family=Open+Sans|Quicksand:400,600,700');
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body,
html,
#root {
  height: 100%;
}

// Common class to reset browser's styles from <button>
.reset-button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  text-align: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  appearance: none;
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

ul.slick-dots{
  bottom:10px
}

button {
  &.slick-prev, &.slick-next{
    z-index: 1;
  }
  &.slick-prev{
    left: 5px;
  }
  &.slick-next{
    right: 5px;
  }
  @media (max-width: 640px) {
    &.slick-prev, &.slick-next{
      visibility: hidden;
    }
    &.slick-prev{
      left: 2px;
    }
    &.slick-next{
      right: 2px;
    }
  }
}

